import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
    JourneyCoreType,
    RecommendedJourneysError,
    useGetRecommendedJourneysQuery
} from '../../graphql/generated/autogenerated'

type GetRecommendedJourneysHook = (assessmentAttemptId: string) => {
    loadingRecommendedJourney: boolean
    refetchRecommendedJourney: () => Promise<any>
    recommendedJourneyError: ApolloError
    inconclusive?: boolean
    recommendedJourneys?: JourneyCoreType[]
    recommendedJourneySpecificError?: RecommendedJourneysError
}

const useGetRecommendedJourneys: GetRecommendedJourneysHook = (
    assessmentAttemptId
) => {
    const { data, loading, refetch, error } = useGetRecommendedJourneysQuery({
        variables: {
            input: {
                assessmentAttemptId: assessmentAttemptId
            }
        },
        skip: assessmentAttemptId === null || assessmentAttemptId === undefined
    })

    const [inconclusive, setInconclusive] = useState<boolean>(undefined)
    const [recommendedJourneys, setRecommendedJourneys] = useState<
        JourneyCoreType[]
    >([])
    const [
        recommendedJourneySpecificError,
        setRecommendedJourneySpecificError
    ] = useState<RecommendedJourneysError>(undefined)

    useEffect(() => {
        if (data?.assessment?.getRecommendedJourneys) {
            const response = data.assessment.getRecommendedJourneys

            switch (response.__typename) {
                case 'RecommendedJourneysError':
                    setRecommendedJourneySpecificError(response)
                    setRecommendedJourneys(undefined)
                    setInconclusive(undefined)
                    break
                case 'RecommendedJourneysInconclusive':
                    setRecommendedJourneySpecificError(undefined)
                    setRecommendedJourneys(undefined)
                    setInconclusive(response.inconclusive)
                    break
                case 'RecommendedJourneysSuccess':
                    setRecommendedJourneySpecificError(undefined)
                    setRecommendedJourneys(response.recommendedJourneys)
                    setInconclusive(response.inconclusive)
                    break
            }
        }
    }, [data])

    return {
        loadingRecommendedJourney: loading,
        recommendedJourneyError: error,
        refetchRecommendedJourney: refetch,
        inconclusive,
        recommendedJourneys,
        recommendedJourneySpecificError
    }
}

export default useGetRecommendedJourneys
