export * from './route'
export * from './sentry'

export enum Edge {
    Start = 'start',
    End = 'end'
}

export enum SurveyStatus {
    High = 'High',
    Some = 'Some',
    Low = 'Low',
    None = 'None',
    NA = 'N/A',
    Collecting = 'Collecting'
}

export enum AlertMessage {
    Success = 'Success'
}

export enum RangeOptionKey {
    Last14Days = 'Last14Days',
    Last30Days = 'Last30Days',
    Last60Days = 'Last60Days',
    Last180Days = 'Last180Days'
}

export enum EmployeeEngagementView {
    Chart,
    Table
}

export enum TrackingType {
    Sleep = 'sleep',
    Step = 'steps'
}

export enum TimeRange {
    ThreeMonths = '3m',
    SixMonths = '6m',
    OneYear = '1y'
}

export enum SelcedtedView {
    Chart,
    Table
}

export enum TypeOfBreakdownItem {
    Strength = 'strength',
    Opportunity = 'opportunity'
}

export enum CoreHealthBehaviorForBehavioralProfileChart {
    Sleep = 'Sleep',
    SleepFood = 'SleepFood',
    Food = 'Food',
    FoodMovement = 'FoodMovement',
    Movement = 'Movement',
    MovementStressManagement = 'MovementStressManagement',
    StressManagement = 'Stress Management',
    StressManagementConnection = 'StressManagementConnection',
    Connection = 'Connection',
    ConnectionSleep = 'ConnectionSleep'
}
