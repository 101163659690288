import { Provider } from 'react-redux'
import Router from './routes'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import { HumanApiProvider } from '@thriveglobal/thrive-web-wearables-core'
import apolloCacheOptions from './graphql/cacheConfig/apolloCacheOptions'
import DemoModeContextContainer from './context/DemoModeContextContainer'
import HasTakenBaselineAssessmentContextContainer, {
    HasTakenBaselineAssessmentContext
} from './context/HasTakenBaselineAssessmentContext'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)

export default function Root() {
    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            commitHash={process.env.GIT_REF}
            apolloClientOptions={{
                name: 'thrive-web-pulse-insights',
                cacheOptions: apolloCacheOptions
            }}
            disableMaxWidth
        >
            <Provider store={store}>
                <section>
                    <DemoModeContextContainer>
                        <HasTakenBaselineAssessmentContextContainer>
                            <HumanApiProvider>
                                <Router />
                            </HumanApiProvider>
                        </HasTakenBaselineAssessmentContextContainer>
                    </DemoModeContextContainer>
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
