import { JourneyCoreType } from '../graphql/generated/autogenerated'

export const SURVEY_PENDING_STATUS = 'PENDING'
export const SURVEY_SAVED_STATUS = 'SAVED'
export const SURVEY_PREVIOUSLY_SAVED_STATUS = 'PREVIOUSLY_SAVED'
export const PULSE_CHECKIN_REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const BAR_CHART_AXIS_MAX_LINE_LENGTH = 9
export const BAR_CHART_AXIS_LABEL_MAX_LINES = 5
export const BAR_CHART_AXIS_ELLIPSIS_LENGTH = 1

export const EXTRA_SMALL_MAX_WIDTH = 470
export const SMALL_MAX_WIDTH = 600
export const MEDIUM_MAX_WIDTH = 900
export const LARGE_MAX_WIDTH = 1200
export const EXTRA_LARGE_MIN_WIDTH = 1536
export const DCI_MICROSTEP_ID = '959da5b0-8dd4-4e38-9f9c-c94b3c0249c6'
export const RESET_MICROSTEP_ID = '58e5b01d-ffa9-472d-b1f9-928815c9033c'
export const JOURNEY_KEYS = [
    'other',
    'money',
    'connect',
    'focus',
    'stressManagement',
    'move',
    'food',
    'recharge'
]

export const JOURNEY_KEY_ICON_MAPPING = {
    recharge: 'bed-front',
    food: 'carrot',
    move: 'person-walking',
    stressManagement: 'head-side-brain',
    focus: 'bullseye-arrow',
    connect: 'heart',
    money: 'credit-card',
    other: 'shapes'
}

export const JOURNEY_KEY_CORE_TYPE_MAPPING = {
    recharge: JourneyCoreType.Recharge,
    food: JourneyCoreType.Food,
    move: JourneyCoreType.Move,
    stressManagement: JourneyCoreType.StressManagement,
    focus: JourneyCoreType.Focus,
    connect: JourneyCoreType.Connect,
    money: JourneyCoreType.Money,
    other: 'Other'
}

export const LINE_CHART_COLOR_PALETTE = {
    ENERGY: '#9753F6',
    ENTHUSIASM: '#F8CB5E',
    EFFECTIVENESS: '#38CBEC'
}

export const BAR_CHART_COLOR_PALETTE = {
    HIGH_RISK: '#E3653E',
    MEDIUM_RISK: '#ECEBF7',
    THRIVING: '#5DDDB7'
}

export const CARD_PLACEHOLDER_IMAGE =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

export const DEFAULT_RECOMMENDATIONS_LIMIT = 6

export const REQUESTER_APP = 'pulse-web'
