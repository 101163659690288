export enum OtherThriveApps {
    // other thrive apps
    ThriveRoot = '/',
    ThriveHome = '/journeys',
    Reset = '/reset',
    ResetHome = '/reset',
    ResetItem = '/reset/thrive/:id',
    Learn = '/learn/courses',
    Challenges = '/challenges',
    Integrations = '/profile/integrations'
}

export enum SharedRoutes {
    // shared routes for both thrive3 & non-thrive3 apps
    AccessRestricted = '/insights/access-restricted'
}

export enum Routes {
    // insights/pulse app
    Insights = '/insights',
    AdminDashboard = '/pulse/admin',
    Welcome = '/insights/welcome',
    Wellbeing = '/insights/your-wellbeing',
    CheckinHistory = '/insights/your-checkins'
}

export enum ThriveThreeRoutes {
    // insights/pulse app
    Insights = '/insights',
    AdminDashboard = '/pulse',
    Welcome = '/insights/welcome',
    Wellbeing = '/insights/your-wellbeing',
    CheckinHistory = '/insights/your-checkins'
}

export enum WellbeingDetailsRoutes {
    Belonging = 'belonging',
    Effectiveness = 'effectiveness',
    Energy = 'energy',
    Enthusiasm = 'enthusiasm',
    Productivity = 'productivity'
}
