import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    CardProps,
    Stack,
    SxProps,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafChip,
    LeafFixedMediaCard,
    LeafFlexMediaCard,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'

const ASSESSMENT_CARD_THUMBNAIL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/84dc7520-6ba3-4098-91c6-aa9921b7a300/cms'

interface ProgressAssessmentEligibleCardProps {
    handleDismissForNow: () => void
    handleAssessmentStarted: () => void
    sx?: SxProps
}

const messages = defineMessages({
    cardHeader: {
        defaultMessage: 'Get insights and recommendations',
        description:
            'Title of section that prompts the user to take a well-being assessment'
    },
    betaLabel: {
        defaultMessage: 'Beta',
        description: 'Label for a new feature that is in Beta status'
    },
    cardBody: {
        defaultMessage:
            'Answer four to five short questions for more personalized insights and recommendations. It should only take about three minutes.',
        description:
            'Brief description of the assessment the user is being asked to take.'
    },
    dismissButtonLabel: {
        defaultMessage: 'Maybe later',
        description:
            "Label for button that will dismiss the user's assessment for now"
    },
    dismissButtonAriaLabel: {
        defaultMessage: 'Skip assessment right now',
        description:
            "Accessible label for button that will dismiss the user's assessment for now"
    },
    takeAssessmentButtonLabel: {
        defaultMessage: 'Take assessment',
        description: "Button text that will start the user's assessment."
    }
})

const ProgressAssessmentEligibleCard: React.FC<
    ProgressAssessmentEligibleCardProps
> = ({ handleDismissForNow, handleAssessmentStarted, sx }) => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const intl = useIntl()

    return (
        <LeafFixedMediaCard
            mediaWidth={'md'}
            alignMedia={smDown ? 'top' : 'left'}
            media={
                <CardMedia
                    image={ASSESSMENT_CARD_THUMBNAIL}
                    sx={{
                        backgroundSize: 'contain',
                        minWidth: '200px !important',
                        width: '100%',
                        height: 'auto'
                    }}
                />
            }
            sx={sx}
        >
            <CardHeader
                title={
                    <Stack
                        gap={1}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                    >
                        <span>{intl.formatMessage(messages.cardHeader)}</span>
                        <LeafChip
                            label={intl.formatMessage(messages.betaLabel)}
                            variant={'outlined'}
                            color={'primary'}
                        />
                    </Stack>
                }
                titleTypographyProps={{ variant: 'h5' }}
            />
            <CardContent sx={{ py: 0 }}>
                <CoreTypography variant={'body1'}>
                    {intl.formatMessage(messages.cardBody)}
                </CoreTypography>
            </CardContent>
            <CardActions
                sx={{
                    justifyContent: 'end',
                    flexDirection: smDown ? 'column-reverse' : 'row'
                }}
            >
                <Button
                    variant={'contained'}
                    color={'info'}
                    onClick={handleDismissForNow}
                    fullWidth={smDown}
                    aria-label={intl.formatMessage(
                        messages.dismissButtonAriaLabel
                    )}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.dismissButtonLabel)}
                    </CoreTypography>
                </Button>
                <Button
                    variant={'contained'}
                    onClick={handleAssessmentStarted}
                    fullWidth={smDown}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.takeAssessmentButtonLabel)}
                    </CoreTypography>
                </Button>
            </CardActions>
        </LeafFixedMediaCard>
    )
}

export default ProgressAssessmentEligibleCard
