import { useAppSelector } from '@thriveglobal/thrive-web-core'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { useCallback } from 'react'

/**
 *
 * @returnsA function returning a date object in the timezone of the user's settings. If no timezone exists in the user's settings the default date object will be returned.
 */
export const useZonedDate = () => {
    const { timezone } = useAppSelector((state) => state.settings)

    return useCallback(
        (date: Date = new Date()) =>
            timezone ? utcToZonedTime(date, timezone) : date,
        [timezone]
    )
}

export default useZonedDate
