import { Fade, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const intlMessages = defineMessages({
    welcomeTitle: {
        defaultMessage:
            'Thanks {firstName}, drum roll please while we analyze your answers...',
        description:
            'The text content on the loading screen for finding your coach during onboarding'
    }
})

const BACKGROUND_IMAGE =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/df22892e-c4d1-44cb-55d0-68be4ca04e00/cms'

function BaselineAssessmentLoader() {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    const { fullName } = useAppSelector((state) => state.user)
    const firstName = fullName?.trim().split(' ')[0] ?? undefined

    const styles = {
        container: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            background: theme.gradient.main,
            zIndex: 1
        },
        innerStack: {
            px: theme.spacing(3),
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${BACKGROUND_IMAGE})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }

    return (
        <Fade in>
            <Stack sx={styles.container} data-testid="loading-screen-container">
                <Stack
                    sx={styles.innerStack}
                    data-testid="loading-screen-inner"
                >
                    <CoreTypography customVariant={'display3'} align={'center'}>
                        {formatMessage(intlMessages.welcomeTitle, {
                            firstName
                        })}
                    </CoreTypography>
                </Stack>
            </Stack>
        </Fade>
    )
}

export default memo(BaselineAssessmentLoader)
