import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem,
    useGetOrCreateNewProgressAssessmentAttemptMutation
} from '../graphql/generated/autogenerated'
import { useEffect, useMemo, useState } from 'react'
import { ApolloError } from '@apollo/client'

type GetOrCreateProgressAssessmentHook = (
    onAssessmentAttemptLoaded: (
        assessmentAttemptSuccess: AssessmentAttemptSuccess
    ) => void
) => {
    loading: boolean
    error: ApolloError
    getOrCreateNewProgressAssessment: () => Promise<any>
    assessmentAttempt?: AssessmentAttemptSuccess
    assessmentItems?: AssessmentItem[]
    getInProgressOrCreateNewProgressAssessmentError?: AssessmentAttemptError
}

export const useGetOrCreateNewProgressAssessment: GetOrCreateProgressAssessmentHook =
    (onAssessmentAttemptLoaded) => {
        const [assessmentAttempt, setAssessmentAttempt] =
            useState<AssessmentAttemptSuccess>(undefined)
        const [assessmentItems, setAssessmentItems] = useState<
            AssessmentItem[]
        >([])
        const [specificError, setSpecificError] =
            useState<AssessmentAttemptError>(undefined)
        const [getOrCreateNewProgressAssessment, { loading, error }] =
            useGetOrCreateNewProgressAssessmentAttemptMutation({
                onCompleted: (data) => {
                    if (
                        data?.assessment
                            ?.getOrCreateNewProgressAssessmentAttempt
                    ) {
                        const a =
                            data.assessment
                                .getOrCreateNewProgressAssessmentAttempt
                        switch (a.__typename) {
                            case 'AssessmentAttemptSuccess':
                                setAssessmentAttempt(a)
                                if (a && a?.allQuestions !== assessmentItems) {
                                    setAssessmentItems(a.allQuestions)
                                }
                                onAssessmentAttemptLoaded(
                                    data.assessment
                                        .getOrCreateNewProgressAssessmentAttempt as AssessmentAttemptSuccess
                                )
                                break
                            case 'AssessmentAttemptError':
                                setSpecificError(a)
                                break
                        }
                    }
                }
            })

        return {
            loading,
            error,
            getOrCreateNewProgressAssessment,
            assessmentAttempt,
            assessmentItems,
            getInProgressOrCreateNewProgressAssessmentError: specificError
        }
    }
