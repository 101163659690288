import { LeafConfirmationDialog } from '@thriveglobal/thrive-web-leafkit'
import { FC, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import differenceInDays from 'date-fns/differenceInDays'
import { useZonedDate } from '../../../hooks/useZonedDateTime'
import startOfDay from 'date-fns/startOfDay'
import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import { AssessmentAttemptSuccess } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    dialogTitle: {
        defaultMessage: 'Leave your assessment?',
        description: 'Title of dialog to abandon baseline assessment'
    },
    dialogMessage: {
        defaultMessage:
            '{daysRemaining, plural, =0 {Your assessment responses will expire in less than a day. Until then, you can resume at any time. Complete your assessment to get your results.} one {Your assessment responses will expire in {daysRemaining, number} day. Until then, you can resume at any time. Complete your assessment to get your results.} other {Your assessment responses will expire in {daysRemaining, number} days. Until then, you can resume at any time. Complete your assessment to get your results.}}',
        description: 'Body of dialog to abandon baseline assessment quiz'
    },
    continueMessage: {
        defaultMessage: 'Continue assessment',
        description: 'Button title to resume taking the baseline assessment'
    },
    cancelMessage: {
        defaultMessage: 'Leave assessment',
        description: 'Button title to cancel taking the baseline assessment'
    }
})

export type AbandonAssessmentDialogProps = {
    assessmentAttempt: AssessmentAttemptSuccess
    isOpen: boolean
    handleOnClose: () => void
    handleOnContinue: () => void
    handleOnCancel: () => void
}
const DAYS_BEFORE_DELETION = 14

const AbandonAssessmentDialog: FC<AbandonAssessmentDialogProps> = ({
    assessmentAttempt,
    isOpen,
    handleOnClose,
    handleOnCancel,
    handleOnContinue
}) => {
    const { formatMessage } = useIntl()

    const getZonedDate = useZonedDate()
    const daysRemainingCalc = useMemo(() => {
        const date = new Date()
        return (
            DAYS_BEFORE_DELETION -
            Math.abs(
                differenceInDays(
                    getZonedDate(startOfDay(date)),
                    new Date(assessmentAttempt.createdAt)
                )
            )
        )
    }, [DAYS_BEFORE_DELETION])
    const defaultTrackingProps = useMemo(() => {
        const {
            assessmentId,
            assessmentAttemptId,
            name: assessmentName,
            type: assessmentVariant
        } = assessmentAttempt

        return {
            featureType: 'assessment',
            isSystemEvent: false,
            assessmentId,
            assessmentName,
            assessmentVariant,
            assessmentAttemptId
        }
    }, [assessmentAttempt])

    return (
        <LeafConfirmationDialog
            dialogContent={formatMessage(messages.dialogMessage, {
                daysRemaining: daysRemainingCalc
            })}
            dialogTitle={formatMessage(messages.dialogTitle)}
            fullWidth
            primaryButtonOnClick={() => {
                handleOnCancel()

                Avo.surveyAbandoned({
                    activityType: 'baseline_assessment_abandoned',
                    ...defaultTrackingProps
                } as AvoTypes.SurveyAbandonedProperties)
            }}
            primaryButtonText={formatMessage(messages.cancelMessage)}
            secondaryButtonOnClick={() => {
                handleOnContinue()

                Avo.surveyResumed({
                    activityType: 'baseline_assessment_resumed',
                    ...defaultTrackingProps
                } as AvoTypes.SurveyResumedProperties)
            }}
            secondaryButtonText={formatMessage(messages.continueMessage)}
            open={isOpen}
            onClose={handleOnClose}
            data-testid="AbandonAssessmentDialog"
        />
    )
}

export default AbandonAssessmentDialog
