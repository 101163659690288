import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem,
    useGetInProgressOrCreateNewAttemptQuery
} from '../graphql/generated/autogenerated'
import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'

type GetInProgressOrCreateNewAttemptHook = (
    assessmentName: string,
    assessmentVariant: string,
    onAssessmentAttemptLoaded: (
        assessmentAttemptSuccess: AssessmentAttemptSuccess
    ) => void,
    skip?: boolean
) => {
    loading: boolean
    error: ApolloError
    refetch: () => Promise<any>
    assessmentAttempt?: AssessmentAttemptSuccess
    assessmentItems?: AssessmentItem[]
    getInProgressOrCreateNewAttemptError?: AssessmentAttemptError
}

export const useGetInProgressOrCreateNewAttempt: GetInProgressOrCreateNewAttemptHook =
    (
        assessmentName,
        assessmentVariant,
        onAssessmentAttemptLoaded,
        skip = false
    ) => {
        const [assessmentAttempt, setAssessmentAttempt] =
            useState<AssessmentAttemptSuccess>(undefined)
        const [assessmentItems, setAssessmentItems] = useState<
            AssessmentItem[]
        >([])
        const [specificError, setSpecificError] =
            useState<AssessmentAttemptError>(undefined)

        const { data, loading, error, refetch } =
            useGetInProgressOrCreateNewAttemptQuery({
                variables: {
                    input: {
                        name: assessmentName,
                        variant: assessmentVariant
                    }
                },
                skip: skip,
                onCompleted: (data) => {
                    if (data?.assessment?.getInProgressOrCreateNewAttempt) {
                        const a =
                            data.assessment.getInProgressOrCreateNewAttempt
                        switch (a.__typename) {
                            case 'AssessmentAttemptSuccess':
                                setAssessmentAttempt(a)
                                if (a && a?.allQuestions !== assessmentItems) {
                                    setAssessmentItems(a.allQuestions)
                                }
                                onAssessmentAttemptLoaded(
                                    data.assessment
                                        .getInProgressOrCreateNewAttempt as AssessmentAttemptSuccess
                                )
                                break
                            case 'AssessmentAttemptError':
                                setSpecificError(a)
                                break
                        }
                    }
                }
            })

        return {
            loading,
            error,
            refetch,
            assessmentAttempt: assessmentAttempt,
            assessmentItems: assessmentItems,
            getInProgressOrCreateNewAttemptError: specificError
        }
    }
