import { IntlShape } from 'react-intl'

export const formatPlaybackTime = (intl: IntlShape, seconds = 0): string => {
    const safeSeconds = Math.max(seconds, 0)
    const secondsAsDate = new Date(0).setSeconds(safeSeconds)

    return intl.formatTime(secondsAsDate, {
        minute: '2-digit',
        second: '2-digit'
    })
}
