import surveyResponseHistoryPaginationTypePolicy from './surveyResponseHistoryPaginationTypePolicy'
import tutorialConfigKeyFields from './tutorialConfigKeyFields'

const apolloCacheOptions = {
    typePolicies: {
        ...surveyResponseHistoryPaginationTypePolicy,
        ...tutorialConfigKeyFields
    }
}

export default apolloCacheOptions
