import { InMemoryCacheConfig } from '@apollo/client'

// adds a merge policy to the survey responses allowing paginated results to be joined together
// for more info see https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
const surveyResponseHistoryPaginationTypePolicy: InMemoryCacheConfig['typePolicies'] =
    {
        SurveysQuery: {
            fields: {
                responses: {
                    keyArgs: false,
                    merge: (
                        existing = {
                            content: []
                        },
                        incoming
                    ) => {
                        return {
                            content: [...existing.content, ...incoming.content],
                            page: incoming.page,
                            totalPages: incoming.totalPages
                        }
                    }
                }
            }
        }
    }

export default surveyResponseHistoryPaginationTypePolicy
