import { StackProps, Stack, Button, Box } from '@mui/material'
import {
    CoreTypography,
    ErrorScreenVariant,
    LeafErrorCode,
    LeafIcon,
    ThriveLogoLoading
} from '@thriveglobal/thrive-web-leafkit'
import { FC, useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess
} from '../../../graphql/generated/autogenerated'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useGetInProgressOrCreateNewAttempt } from '../../../hooks/useGetInProgressOrCreateNewAttempt'
import Assessment from '../Assessment'

const messages = defineMessages({
    introHeader: {
        defaultMessage: 'First, tell us about yourself',
        description:
            "Introduction text for a survey about the user's well being"
    },
    introSubHeader01: {
        defaultMessage:
            'Answer honestly so we can personalize your experience.',
        description:
            'Asking the user for honest responses to the survey they are about to fill out.'
    },
    introSubHeader02: {
        defaultMessage: 'Remember, there are no right or wrong answers.',
        description:
            'Reminder to the user that there are no incorrect answers to the following survey.'
    },
    introButtonLabel: {
        defaultMessage: 'Get started',
        description: 'Button label indicating start of survey.'
    },
    currentStepHeader: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    },
    errorCodeMessage: {
        defaultMessage:
            "We're having trouble loading this page, please refresh the page or come back later.",
        description:
            'Description of technical difficulties. Asking the user to try again later.'
    },
    outroHeader: {
        defaultMessage: 'Thank you for investing in your well-being.',
        description: 'Thank you message for the end of the survey.'
    },
    outroSubHeader: {
        defaultMessage:
            'Your honest responses will help guide your experience.',
        description: "Sub header describing the impact of the user's action."
    },
    outroButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Label for button that closes the survey.'
    }
})

export interface BaselineAssessmentResponse {
    completed: boolean
    error?: any
    specificError?: AssessmentAttemptError
    assessmentAttempt?: AssessmentAttemptSuccess
}

export interface BaselineAssessmentProps extends StackProps {
    onComplete: (response: BaselineAssessmentResponse) => void
    skipIntro?: boolean
    skipOutro?: boolean
    skipConfirmationDialog?: boolean
}

const BaselineAssessment: FC<BaselineAssessmentProps> = ({
    onComplete,
    skipIntro = false,
    skipConfirmationDialog = false,
    ...stackProps
}) => {
    const intl = useIntl()
    const { userId } = useAppSelector((state) => state.user)
    const [assessmentStarted, setAssessmentStarted] = useState<boolean>(
        skipIntro || false
    )

    const onAssessmentAttemptLoaded = useCallback(
        (attemptSuccess: AssessmentAttemptSuccess) => {
            Avo.surveyServed({
                featureType: 'assessment',
                activityType: 'onboarding_assessment_served',
                isOnboarding: true,
                assessmentId: attemptSuccess.assessmentId,
                assessmentAttemptId: attemptSuccess.assessmentAttemptId,
                assessmentName: attemptSuccess.name,
                assessmentVariant: attemptSuccess.variant,
                userId_: userId,
                surveyLocation: null,
                dispatchedDate: null,
                isSystemEvent: false,
                onboardingAttemptNumber: null
            })
        },
        [userId]
    )

    const {
        loading,
        error,
        assessmentAttempt,
        assessmentItems,
        getInProgressOrCreateNewAttemptError
    } = useGetInProgressOrCreateNewAttempt(
        'baselineAssessment',
        'baseline',
        onAssessmentAttemptLoaded
    )

    const startAssessment = useCallback(() => {
        setAssessmentStarted(true)
        Avo.surveyStarted({
            featureType: 'assessment',
            activityType: 'onboarding_assessment_started',
            isOnboarding: true,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant,
            isSystemEvent: false,
            onboardingAttemptNumber: null,
            responseText: null
        })
    }, [
        assessmentAttempt?.assessmentAttemptId,
        assessmentAttempt?.assessmentId,
        assessmentAttempt?.name,
        assessmentAttempt?.variant
    ])

    useEffect(() => {
        if (skipIntro) {
            startAssessment()
        }
    }, [skipIntro, startAssessment])

    if (!assessmentStarted) {
        return (
            <Stack gap={3} mt={3} textAlign={'center'}>
                <CoreTypography variant={'h2'} component={'h1'}>
                    {intl.formatMessage(messages.introHeader)}
                </CoreTypography>
                <img
                    src={
                        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/20df00fe-b41a-43c1-62ad-35eda1dd2300/cms'
                    }
                    alt={''}
                    style={{
                        width: '300px',
                        maxWidth: '100%',
                        height: 'auto',
                        margin: 'auto'
                    }}
                />
                <Stack>
                    <CoreTypography variant={'h4'}>
                        {intl.formatMessage(messages.introSubHeader01)}
                    </CoreTypography>
                    <CoreTypography variant={'h4'}>
                        {intl.formatMessage(messages.introSubHeader02)}
                    </CoreTypography>
                </Stack>
                <span>
                    <Button
                        variant={'contained'}
                        endIcon={
                            <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                        }
                        onClick={startAssessment}
                        fullWidth={false}
                    >
                        <CoreTypography customVariant={'buttonNormal'}>
                            {intl.formatMessage(messages.introButtonLabel)}
                        </CoreTypography>
                    </Button>
                </span>
            </Stack>
        )
    }

    return (
        <Stack display={'flex'} gap={2} {...stackProps}>
            <Box maxWidth={'600px'} width={'100%'} alignSelf={'center'}>
                <Assessment
                    assessmentAttempt={assessmentAttempt}
                    error={error}
                    assessmentAttemptError={
                        getInProgressOrCreateNewAttemptError
                    }
                    loading={loading}
                    assessmentItems={assessmentItems}
                    onCompleteAssessment={onComplete}
                    responseDirection={'column'}
                    skipConfirmationDialog
                    wrapperVariant={'card'}
                    sx={{ textAlignLast: 'center' }}
                />
            </Box>
        </Stack>
    )
}

export default BaselineAssessment
