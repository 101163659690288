import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem,
    GetOrCreateNewBaselineAssessmentAttemptMutation,
    useGetOrCreateNewBaselineAssessmentAttemptMutation
} from '../../graphql/generated/autogenerated'
import { useState } from 'react'
import { ApolloError, FetchResult } from '@apollo/client'

export type GetOrCreateBaselineAssessmentResponseProps = {
    loading: boolean
    error: ApolloError
    getOrCreateNewBaselineAssessment: () => Promise<
        FetchResult<GetOrCreateNewBaselineAssessmentAttemptMutation>
    >
    assessmentAttempt?: AssessmentAttemptSuccess
    assessmentItems?: AssessmentItem[]
    getInProgressOrCreateNewBaselineAssessmentError?: AssessmentAttemptError
}

type GetOrCreateNewBaselineAssessmentHook = (
    onAssessmentAttemptLoaded: (
        assessmentAttemptSuccess: AssessmentAttemptSuccess
    ) => void
) => GetOrCreateBaselineAssessmentResponseProps

const useGetOrCreateNewBaselineAssessment: GetOrCreateNewBaselineAssessmentHook =
    (onAssessmentAttemptLoaded) => {
        const [assessmentAttempt, setAssessmentAttempt] =
            useState<AssessmentAttemptSuccess>(undefined)
        const [assessmentItems, setAssessmentItems] = useState<
            AssessmentItem[]
        >([])
        const [specificError, setSpecificError] =
            useState<AssessmentAttemptError>(undefined)
        const [getOrCreateNewBaselineAssessment, { loading, error }] =
            useGetOrCreateNewBaselineAssessmentAttemptMutation({
                onCompleted: (data) => {
                    if (
                        data?.assessment?.getOrCreateBaselineAssessmentAttempt
                    ) {
                        const a =
                            data.assessment.getOrCreateBaselineAssessmentAttempt
                        switch (a.__typename) {
                            case 'AssessmentAttemptSuccess':
                                setAssessmentAttempt(
                                    a as AssessmentAttemptSuccess
                                )
                                if (a && a?.allQuestions !== assessmentItems) {
                                    setAssessmentItems(a.allQuestions)
                                }
                                onAssessmentAttemptLoaded(
                                    data.assessment
                                        .getOrCreateBaselineAssessmentAttempt as AssessmentAttemptSuccess
                                )
                                break
                            case 'AssessmentAttemptError':
                                setSpecificError(a)
                                break
                        }
                    }
                }
            })

        return {
            loading,
            error,
            getOrCreateNewBaselineAssessment,
            assessmentAttempt,
            assessmentItems,
            getInProgressOrCreateNewBaselineAssessmentError: specificError
        }
    }

export default useGetOrCreateNewBaselineAssessment
