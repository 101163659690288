import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useSetPageTitle } from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { BrowserRouter, Switch } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop'
import { useRoutes } from '../hooks/useRoutes'

const InsightsHome = lazy(() => import('../components/PersonalDashboardPage'))
const AccessRestricted = lazy(() => import('../pages/AccessRestricted'))
const PillarDetails = lazy(() => import('../pages/PillarDetailsPage'))
const CheckinHistoryPage = lazy(
    () => import('../components/CheckinHistoryPage')
)

const pulsePageTitle = defineMessage({
    defaultMessage: 'Thrive - Insights',
    description: 'Thrive Insights page title'
})

const Router: React.FC = () => {
    const theme = useTheme()
    const intl = useIntl()
    const Routes = useRoutes()

    useSetPageTitle(intl.formatMessage(pulsePageTitle))

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <ScrollToTop>
                        <Switch>
                            <ProtectedRoute
                                path={Routes.AccessRestricted}
                                component={AccessRestricted}
                            />
                            <ProtectedRoute
                                path={`${Routes.Wellbeing}/:wellbeingDetailType`}
                                component={PillarDetails}
                            />
                            <ProtectedRoute
                                path={Routes.CheckinHistory}
                                component={CheckinHistoryPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path={Routes.Insights}
                                component={InsightsHome}
                            />
                        </Switch>
                    </ScrollToTop>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
