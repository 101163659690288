import { ExtendedTheme } from '@thriveglobal/thrive-web-leafkit'
import { JourneyCoreType } from '../../graphql/generated/autogenerated'
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette'

export function getJourneyColorPalette(
    theme: ExtendedTheme,
    journeyCoreType: JourneyCoreType
): SimplePaletteColorOptions {
    switch (journeyCoreType) {
        case JourneyCoreType.Food:
            return theme.palette.journeys.food
        case JourneyCoreType.Recharge:
            return theme.palette.journeys.recharge
        case JourneyCoreType.Move:
            return theme.palette.journeys.move
        case JourneyCoreType.Money:
            return theme.palette.journeys.money
        case JourneyCoreType.Focus:
            return theme.palette.journeys.focus
        case JourneyCoreType.StressManagement:
            return theme.palette.journeys.stressManagement
        case JourneyCoreType.Connect:
            return theme.palette.journeys.connect
        default:
            return theme.palette.journeys.recharge
    }
}
