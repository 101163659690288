import { createContext, useState, FC, ReactNode, useMemo } from 'react'

type HasTakenBaselineAssessmentContextValue = {
    hasTakenBaselineAssessment: boolean
    setHasTakenBaselineAssessment: (hasTakenBaselineAssessment: boolean) => void
}

export const HasTakenBaselineAssessmentContext =
    createContext<HasTakenBaselineAssessmentContextValue>({
        hasTakenBaselineAssessment: false,
        setHasTakenBaselineAssessment: () => {
            throw new Error(
                'attempt to set hasTakenBaselineAssessment without initializing setter, please initialize HasTakenBaselineAssessmentContext with HasTakenBaselineAssessmentContextContainer'
            )
        }
    })

type HasTakenBaselineAssessmentContextContainerProps = {
    children: ReactNode
    /** overrides context value, for unit testing purposes only */
    _debugContextValue?: Partial<HasTakenBaselineAssessmentContextValue>
}

const HasTakenBaselineAssessmentContextContainer: FC<
    HasTakenBaselineAssessmentContextContainerProps
> = ({ children, _debugContextValue }) => {
    const [hasTakenBaselineAssessment, setHasTakenBaselineAssessment] =
        useState(false)

    const contextValue = useMemo(
        () => ({
            hasTakenBaselineAssessment,
            setHasTakenBaselineAssessment,
            ..._debugContextValue
        }),
        [hasTakenBaselineAssessment, _debugContextValue]
    )

    return (
        <HasTakenBaselineAssessmentContext.Provider value={contextValue}>
            {children}
        </HasTakenBaselineAssessmentContext.Provider>
    )
}

export default HasTakenBaselineAssessmentContextContainer
