import { GivenResponse } from '../../graphql/generated/autogenerated'

//returns the last non-null assessment response, 1-indexed

export function getAssessmentProgressCount(responses: GivenResponse[]) {
    if (responses === undefined || responses.length === 0) {
        return 0
    }

    let responseIndex = responses.length - 1
    while (responseIndex >= 0) {
        const curResponse = responses[responseIndex]?.response?.value
        if (curResponse !== null && curResponse !== undefined) {
            return responseIndex + 1 //plus one bc assessment step is 1 indexed
        } else {
            responseIndex--
        }
    }
    return 0
}
