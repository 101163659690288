import {
    createContext,
    useState,
    FC,
    ReactNode,
    useCallback,
    useMemo
} from 'react'

type DemoModeContextValue = {
    isDemoMode: boolean
    setIsDemoMode: (isDemoMode: boolean) => void
    toggleDemoMode: () => void
}

export const DemoModeContext = createContext<DemoModeContextValue>({
    isDemoMode: false,
    setIsDemoMode: () => {
        throw new Error(
            'attempt to set isDemoMode without initializing setter, please initialize DemoModeContext with DemoModeContextContainer'
        )
    },
    toggleDemoMode: () => {
        throw new Error(
            'attempt to toggle isDemoMode without initializing setter, please initialize DemoModeContext with DemoModeContextContainer'
        )
    }
})

type DemoModeContextContainerProps = {
    children: ReactNode
    /** overrides context value, for unit testing purposes only */
    _debugContextValue?: Partial<DemoModeContextValue>
}

const DemoModeContextContainer: FC<DemoModeContextContainerProps> = ({
    children,
    _debugContextValue
}) => {
    const [isDemoMode, setIsDemoMode] = useState(false)

    const toggleDemoMode = useCallback(() => {
        setIsDemoMode((currentIsDemoMode) => !currentIsDemoMode)
    }, [setIsDemoMode])

    const contextValue = useMemo(
        () => ({
            isDemoMode,
            setIsDemoMode,
            toggleDemoMode,
            ..._debugContextValue
        }),
        [isDemoMode, setIsDemoMode, toggleDemoMode, _debugContextValue]
    )

    return (
        <DemoModeContext.Provider value={contextValue}>
            {children}
        </DemoModeContext.Provider>
    )
}

export default DemoModeContextContainer
